const Thankyou = () => 
{
    return (
        <div>
            Thankyou for your registration
        </div>
        );

};
export default Thankyou;
