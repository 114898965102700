
import React, { Component } from "react";
// Table from react-bootstrap
import { Table } from "react-bootstrap";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse";
import { Container } from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
const Header = () => 
{
return (

 <Navbar collapseOnSelect expand="lg" class="navbar navbar-expand-lg navbar-light bg-light">
 <Container>
 <Navbar.Toggle aria-controls="responsive-navbar-nav" />
 <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/Register">Register</Nav.Link>
          <Nav.Link href="/Login">Login</Nav.Link>
        </Nav>
        </Navbar.Collapse>
    </Container>
  </Navbar>
);
};
export default Header;



