import logo from  './logo.svg';
import React from "react";

import './App.css';
import Header from './Header';
import Home from './Home';
import Login from './login';
import Register from './Register';
import Thankyou from './thankyou';


import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
const App = () => {
return (
<Router>
<div className="container">
<Header/>
<Routes>
<Route path="/" element={<Home/>} />
<Route path="/login" element={<Login/>} />
<Route path="/Register" element={<Register/>} />
<Route path="/Thankyou" element={<Thankyou/>} />
</Routes>
</div>
</Router>
);
};
export default App;
