import React from "react";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { useNavigate } from 'react-router';

const RegisterForm = () => {
  let navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm();
  //const handleRegistration = (data) => console.log(data);
  const handleError = (errors) => {};
  const handleRegistration = data => {
    axios
     .post(
         'https://intel.shobiztech.com/php-react/insert.php',data).then(function(response) 
        {
         if(response.data="valid")
         {
         navigate('/thankyou')
          }
         
      })
      this.setState = ({
        name: "",
        email: "",
        password: ""
      })
     
 };


  const registerOptions = {
    name: { required: "Name is required" },
    email: { required: "Email is required" },
    password: {
      required: "Password is required",
      minLength: {
        value: 8,
        message: "Password must have at least 8 characters"
      }
    }
  };

  return (
 <div className="main-box">
    <form onSubmit={handleSubmit(handleRegistration, handleError)}>
  <div className="row"> 
<div className="col-md-12 text-center"> <h1> Register</h1> 
</div>
</div>

<div className="row">
<div className="col-md-6"> First Name</div>
<div className="col-md-6"><input name="name" type="text" {...register('name', registerOptions.name) }/>
<small className="text-danger">
          {errors?.name && errors.name.message}
        </small>
</div>
</div>

<div className="row">
<div className="col-md-6"> Email</div>
<div className="col-md-6"><input
          type="email"
          name="email"
          {...register('email', registerOptions.email)}
        />
<small className="text-danger">
          {errors?.email && errors.email.message}
        </small>
</div>
</div>

<div className="row">
<div className="col-md-6"> Password</div>
<div className="col-md-6"> <input
          type="password"
          name="password"
          {...register('password', registerOptions.password)}
        />
<small className="text-danger">
          {errors?.password && errors.password.message}
        </small>
</div>
</div>

    <button className="btn btn-success">Submit</button>
    </form>
 </div>
  );
};
export default RegisterForm;